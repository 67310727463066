import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PreviewPage from "./pages/preview_page/PreviewPage";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<PreviewPage />} />
          <Route path="*" element={<h1>Page not found</h1>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
